<template>
  <div>
    <div class="row maindashboard-director">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              <!-- (금일) 진행중인 업무 -->
              {{$label('LBL0000940')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCd1"
                  :isFirstValue="false"
                  label=""
                  v-model="plantCd1"
                  @datachange="getWorkProgress" />
              </div>
            </div>
            <div class="card-body" style="height: 213px">
              <div class="row">
                <div v-for="(progress, idx) in workProgressList" 
                  :key="idx"
                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <q-btn dense :icon="iconItems[idx]" align="left" :color="colorItems2[idx]" outline  unelevated :label="progress.workName" class="btn-fixed-width-height">
                    <q-badge color="red" floating transparent>
                      {{ progress.workCount }}
                    </q-badge>
                  </q-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              <!-- 개선 진행 목록 -->
              {{$label('LBL0000941')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCd3"
                  :isFirstValue="false"
                  label=""
                  v-model="plantCd3"
                  @datachange="getImprProgress"/>
              </div>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="grid2"
                tableId="grid2"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="217px"
                :columns="grid2.columns"
                :data="grid2.data"
                @linkClick="linkClickImp"
              >
              </c-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <!-- 작업허가 현황 [S]-->
        <div ref="main-dashborad" class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            작업허가서 현황
            <q-btn 
              flat round 
              color="grey" 
              class="mainmapstart fullscreen-btn"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'" 
              @click="clickFullScreen">
              <q-tooltip v-if="fullscreen">
                <!-- 원래대로 -->
                {{$label('LBL0000327')}}
              </q-tooltip>
              <q-tooltip v-else>
                <!-- 전체화면 -->
                {{$label('LBL0000328')}}
              </q-tooltip>
            </q-btn>
            <div class="card-more main-header-input main-header-input2 main-header-input2-director">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
            </div>
          </div>
          <div class="card-body" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <template v-if="mapObj.activeTask===1">
                    <!-- 직접 위치를 선택한 marked -->
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :class="item.idx === mapObj.activeMarked ? 'selected' : ''"
                      :resizable="false"
                      :parent="true"
                      :draggable="false"
                      :x="item.locationXcoordinate * mapRate * mapObj.ratio"
                      :y="item.locationYcoordinate * mapRate * mapObj.ratio"
                      :w="40 " :h="40"
                      :grid="[20, 20]"
                      @activated="onMarkedInfo(item)"
                    >
                      <c-map-marker :isSelected="item.idx === mapObj.activeMarked" />
                      <q-menu 
                        :ref="'markedproxy' + item.idx" 
                        :content-class="'mainMapMenuLayer'"
                        anchor="top right"
                        self="top left">
                        <q-card 
                          v-for="(workPermit, idx) in mapObj.selectedWorkPermit.workPermits"
                          :key="idx"
                          flat bordered 
                          @click="openWorkPermitPop(workPermit)"
                          :class="['main-work-permit-card main-work-permit-card-cursor', setStepClass(workPermit.swpStepCd)]">
                          <q-item class="main-work-permit-card-section main-work-permit-card-item">
                            <q-item-section avatar>
                              <q-badge rounded :color="setStepColor(workPermit.swpStepCd)" :label="workPermit.swpStepName" />
                            </q-item-section>
                            <q-item-section>
                              <q-item-label>
                                <q-icon 
                                  v-for="(icon, iconIdx) in setTypeIcons(workPermit.sopWorkTypeCds)"
                                  :key="iconIdx"
                                  :name="icon" 
                                  class="text-red"
                                  size="25px" />
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                          <q-card-section class="main-work-permit-card-section">
                            <q-icon 
                              name="chevron_right" 
                              class="text-indigo" 
                            />
                            <b>{{workPermit.workSummary}}</b> ({{workPermit.workTime}})
                          </q-card-section>
                        </q-card>
                      </q-menu>
                    </VueDraggableResizable>
                  </template>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$comm.getLangMessage('MSG0000122')"></el-empty>
          </div>
        </div>
        <!-- 작업허가 현황 [E]-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 아차사고 현황 -->
            {{$label('LBL0000942')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCdAcc"
                :isFirstValue="false"
                label=""
                v-model="plantCdAcc"
                @datachange="getNearmiss"/>
            </div>
          </div>
          <div class="card-body">
            <c-table
              ref="gridAcc"
              tableId="gridAcc"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="310px"
              :columns="gridAcc.columns"
              :data="gridAcc.data"
              @linkClick="linkClickAcc"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 통합개선 이행율 현황 -->
            {{$label('LBL0000943')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                label=""
                :isFirstValue="false"
                name="plantCd4"
                v-model="plantCd4"
                @datachange="datachange4" />
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                type="year"
                default="today"
                name="actionCompleteRequestDate"
                @input="getImprStatus"
                v-model="actionCompleteRequestDate"
              />
            </div>
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart" 
              height="300px" 
              type="bar"
              @dataPointSelection="selectedBarImp"
              :width="imprChart.chartWidth"
              :options="imprChart.chartOptions" 
              :series="imprChart.series"></apexchart>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: 'main-dashboard-director',
  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      settingSeconds: 5,
      fullscreen: false,
      mapObj: {
        activeTask: 1,
        activeMarked: 0,
        selectedWorkPermit: {
          workPermits: [],
        },
        envUrl: '',
        markInfoUrl: '',
        url: '',
        taskParam: {
          workPermitPeriod: [],
        },
        location: {
          mdmLocationId: '',
          locationName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        maps: [],
        orignMaps: [],
        slide: 0,
        height: 800,
        ratio: 1,
        orignRatio: 1,
        endIdx: 1,
        loading: false,
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      plantCd1: null,
      plantCd2: null,
      plantCd3: null,
      plantCd4: null,
      plantCdAcc: null,
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          title: {
            text: '',
            align: 'left',
            style: {
              fontSize: "14px",
              color: '#1ab394'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            width: [3,3,3,3,3,3,3,3],
            curve: 'straight',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '100%',
      },
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          title: {
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      rate: [],
      safRate: [],
      envRate: [],
      yearUrl: '',
      accident: {
        startYmd: '',
        endYmd: '',
      },
      grid1: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            // 작업개요
            label: 'LBL0000211',
            align: 'left',
            sortable: false,
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            // 허가일
            label: 'LBL0000213',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            // 허가서구분
            label: 'LBL0000204',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: "link",
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridAcc: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            // 아차사고명
            label: "LBL0000949",
            align: "left",
            sortable: true,
            ellipsis: true,
            type: "link",
          },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            style: 'width:90px',
            // 진행단계
            label: "LBLPROGRESS",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            style: 'width:110px',
            // 유형(LBLTYPE) -> 아차사고종류
            label: '아차사고종류',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      rateUrl: '',
      patrolUrl: '',
      workPermitUrl: '',
      imprProgressUrl: '',
      workProgressUrl: '',
      actionCompleteRequestDates: [],
      workProgressList: [],
      showTooltopCnt: 0,
      showTooltop: [false,false,false,false,false,false,false,false],
      iconItems: ['school','settings_applications','build','build_circle','restore','manage_accounts','report','report_problem'],
      colorItems2: ['primary','secondary','amber-9','deep-orange','secondary','brown-5','purple','green-7',],
      ibmTaskTypeCdList: [],
      actionCompleteRequestDate: '',
      interval: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  activated() {
    this.getMapData();
  },
  deactivated() {
    window.removeEventListener('resize', this.setSize);
    clearInterval(this.mapObj.interval.obj);
    clearInterval(this.interval);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
    clearInterval(this.mapObj.interval.obj);
    clearInterval(this.interval);
  },
  computed: {
    colorItems() {
      return [
        // 계획수립
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$comm.getLangLabel('LBL0000381'), colorClass: 'blue' },
        // 점검중
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$comm.getLangLabel('LBL0000382'), colorClass: 'orange' },
        // 점검완료
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$comm.getLangLabel('LBL0000383'), colorClass: 'green' },
        // 지연
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$comm.getLangLabel('LBL0000384'), colorClass: 'red' },
      ]
    },
    mapRate() {
      return 0.870
    },
  },
  methods: {
    init() {
      this.showTooltopCnt = 0
      this.yearUrl = selectConfig.sop.iim.accident.status.year.url;
      this.rateUrl = selectConfig.main.imprChart.url;
      this.patrolUrl = selectConfig.main.patrolChart.url;
      this.workPermitUrl = selectConfig.main.workPermitStatus.url;
      this.imprProgressUrl = selectConfig.main.imprProgress.url;
      this.workProgressUrl = selectConfig.main.workProgress.url;

      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url

      this.actionCompleteRequestDate = this.$comm.getThisYear();

      // 년도 설정
      let years = [];
      this.setLangLabel();
      for (let i = 0; i < 5; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.yearOcuurChart.chartOptions.xaxis.categories = years;

      this.accident.startYmd = years[0];
      this.accident.endYmd = years[4];

      this.getImprStatus(null);
      this.getImprProgress();
      this.getWorkProgress();
      this.getNearmiss();
      
      this.getMapPriodParam();
      // this.getMapData();
    },
    setSize() {
      setTimeout(() => {
        this.getMapData(true);
      }, 300);
    },
    clickFullScreen() {
      let element = this.$refs['main-dashborad']
      if (this.fullscreen === false) {
        this.fullscreen = true;
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) { // Native full screen.
          requestMethod.call(element);
        }
      } else {
        this.fullscreen = false;
        let requestMethod = document.exitFullscreen || document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msExitFullscreen;
        if (requestMethod) { // Native full screen.
          requestMethod.call(document);
        }		
      }
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
      },);
    },
    getMapData(loading) {
      clearInterval(this.interval);
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          /**
           * 지도는 1개
           */
          this.$_.forEach(_result.data, item => {
            this.mapObj.endIdx = 1;
            if (item.otherMaps && item.otherMaps.length > 0) {
              this.$_.forEach(item.otherMaps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
            if (item.maps && item.maps.length > 0) {
              this.$_.forEach(item.maps, map => {
                this.$set(map, 'idx', this.mapObj.endIdx)
                this.mapObj.endIdx++;
              })
            }
          })

          this.mapObj.maps = this.$_.cloneDeep(_result.data)
          this.mapObj.orignRatio = this.mapObj.maps[0].ratio;
          
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
          
          this.mapObj.height = this.fullscreen ? 900 : 550
          let resizeRatio = Math.round(this.mapObj.height / (this.fullscreen ? 900 : 575) * 100) / 100;
          let _height = this.mapObj.height * this.mapRate
          this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
          this.mapObj.ratio = resizeRatio

          // 로딩바 제거
          if (loading) {
            this.mapObj.loading = false;
          }
          // let _idx = 0;
          // this.interval = setInterval(() => {
          //   if (this.$refs['markedproxy' + (_idx - 1)] !== undefined) {
          //     this.$refs['markedproxy' + (_idx - 1)][0].hide()
          //   }
          //   if (this.$refs['markedproxy' + (_idx)] !== undefined) {
          //     this.$set(this.mapObj.selectedWorkPermit, 'workPermits', this.mapObj.maps[0].maps[_idx].resultTypes);
          //     this.$refs['markedproxy' + _idx][0].show()
          //   }
          //   if ((this.mapObj.maps[0].maps.length + 1) == _idx) {
          //     _idx = 0;
          //   }
          //   this.$set(this.mapObj, 'activeMarked', _idx)
          //   _idx++;
          // }, 3000);
        }
      },);
    },
    onMarkedInfo(markedInfo) {
      this.$set(this.mapObj.selectedWorkPermit, 'workPermits', markedInfo.resultTypes);
      this.$set(this.mapObj, 'activeMarked', markedInfo.idx)
    },
    /* eslint-disable no-unused-vars */
    setStepColor(swpStepCd) {
      let color = '';
      switch(swpStepCd) {
        case 'SS00000005':
          color = 'light-blue';
          break;
        case 'SS00000010':
          color = 'amber';
          break;
        case 'SS00000020':
          color = 'light-green';
          break;
      }
      return color;
    },
    setStepClass(swpStepCd) {
      let _class = '';
      switch(swpStepCd) {
        case 'SS00000005':
        _class = 'main-work-permit-card-light-blue';
          break;
        case 'SS00000010':
        _class = 'main-work-permit-card-amber';
          break;
        case 'SS00000020':
        _class = 'main-work-permit-card-light-green';
          break;
      }
      return _class;
    },
    setTypeIcons(sopWorkTypeCds) {
      let icons = [];
      let typeArray = sopWorkTypeCds && sopWorkTypeCds.length > 0 ? sopWorkTypeCds.split(',') : [];
      if (typeArray && typeArray.length > 0) {
        this.$_.forEach(typeArray, type => {
          switch(type) {
            case 'SPT0000001': // 일반
              icons.push('engineering');
              break;
            case 'SPT0000005': // 화기
              icons.push('local_fire_department');
              break;
            case 'SSWT000001': // 밀폐공간
              icons.push('dangerous');
              break;
            case 'SSWT000005': // 정전
              icons.push('flash_off');
              break;
            case 'SSWT000010': // 방사선
              icons.push('warning');
              break;
            case 'SSWT000015': // 굴착
              icons.push('hardware');
              break;
            case 'SSWT000020': // 고소
              icons.push('stairs');
              break;
            case 'SSWT000025': // 중장비
              icons.push('agriculture');
              break;
          }
        })
      }
      return icons;
    },
    openWorkPermitPop(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePermitPopup;
    },
    closePermitPopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setLangLabel() {
      let thisVue = this;
      this.imprChart.series = [
      {
          id: 'actioning',
          name: this.$comm.getLangLabel('LBL0000944'), // 조치중
          data: [],
        },
        {
          id: 'complete',
          name: this.$comm.getLangLabel('LBL0000945'), // 개선완료(총합)
          data: [],
        },
        {
          id: 'overAction',
          name: this.$comm.getLangLabel('LBL0000934'), // 지연 건수
          data: [],
        },
      ]
      this.imprChart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    getWorkProgress() {
      this.$http.url = this.workProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd1
      }
      this.$http.request((_result) => {
        this.workProgressList = _result.data
      },);
    },
    getImprProgress() {
      this.$http.url = this.imprProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd3,
      };
      this.$http.request((_result) => {
        this.grid2.data = _result.data
      },);
    },
    getNearmiss() {
      this.$http.url = selectConfig.sop.iim.accident.near.list.url; //selectConfig.sop.iim.accident.near.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: '',
        endYmd: '',
      };
      this.$http.request((_result) => {
        this.gridAcc.data = _result.data
      },);
    },
    datachange4() {
      this.getImprStatus();
    },
    getImprStatus() {
      let dates = [];
      if (this.actionCompleteRequestDate !== null) {
        dates = [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31'];
      }
      this.$http.url = this.rateUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd4,
        actionCompleteRequestDates: dates,
      }
      this.rate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          this.ibmTaskTypeCdList.push({
            code: task.ibmTaskTypeCd,
            codeName: task.ibmTaskTypeName,
          });
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
    linkClickAcc(row) {
      this.popupOptions.title = 'LBL0000946'; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
        iimStatusNearCd: row ? row.iimStatusNearCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/near/nearAccidentDetail.vue'}`);
      this.popupOptions.width = "90%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClickImp(row) {
      this.popupOptions.title = 'LBL0000947'; // 개선 상세
      this.popupOptions.param = {
        sopImprovementId: row ? row.sopImprovementId : '',
        ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
      };
      this.popupOptions.width = "80%"
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    selectedBarImp(e, chart, opts) {
      this.popupOptions.width = '90%';
      this.popupOptions.title = "LBL0000948"; // 개선목록 (업무별 현황)
      this.popupOptions.param = {
        plantCd: this.plantCd4,
        ibmTaskTypeCd: this.$_.filter(this.ibmTaskTypeCdList, { codeName: this.imprChart.chartOptions.xaxis.categories[opts.dataPointIndex] })[0].code,
        actionCompleteRequestDates: [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31']
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/impr.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>
<style lang="sass">
.main-work-permit-card-cursor
  cursor: pointer
.maindashboard-director 
  .fullscreen-btn
    .q-btn__wrapper
      min-width: 10px !important
      min-height: 10px !important
  .no-padding
    padding: 0px 0px 6px 0px !important
  .cardcontents
    box-shadow: 0 !important
    border: 1px solid rgba(0,0,0,0.12) !important
    border-radius: 10px !important
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
      border-radius: 0 0 10px 10px !important
      background: #fff
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      border-bottom-width: 0px !important
      border-radius: 10px 10px 0 0 !important
      height: 40px
      color: #0072c6
      background: #f8f8f9
      display: inline-block
      padding-top: 8px
      width: 100%
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
  .main-header-input
    margin-top: -5px
    padding-right: 5px
  .main-header-input2
    margin-top: -5px
    padding-right: 5px
    width: 240px !important
    .customDate
      padding-bottom: 0 !important
      .q-field__inner
        padding: 0 !important
  .cardcontents .card-body.nopadgrid
    padding-top: 0px !important
  .btn-fixed-width-height
    width: 95%
    margin: 3px !important
    pointer-events: none

  .directorMainLayer
    .workPermit-carousel
      border-radius: 10px
      height: var(--map-height) !important
      .q-carousel__slide
        width: calc(var(--map-ratio) * var(--map-height)) !important
        max-width: calc(var(--map-ratio) * var(--map-height)) !important
        position: relative
      .q-carousel--padding, .q-carousel__slide
        padding: 0 !important
      .q-carousel__arrow
        transition: all 0.5s
        opacity: 0.2
      .q-carousel__arrow:hover
        transition: all 0.5s
        opacity: 0.8
    .caption-work2
      font-size: 0.9em
      white-space: normal
      color: #fff
      padding: 10px
    .caption-work
      font-size: 0.9em
      // font-style: italic
      white-space: normal
      color: #555
      margin-top: 4px
    .mainmapstart
      float: right
      margin-right: 0px
      margin-top: -5px
    .mainMarkImage
      padding: 0px !important
      font-size: 20px
      height: 30px !important
      position: absolute
      padding: 16px
      color: #C10015
      z-index: 3 !important
      .markImage-abbreviation-parent
        text-align: start
        position: absolute
        .markImage-abbreviation
          cursor: pointer
          display: inline-block
          width: auto
          border-left: 1px solid #757575
          border-right: 1px solid #757575
          border-top: 1px solid #757575
          border-bottom: 1px solid #757575
          border-radius: 5px
          .markImage-tran
            border-radius: 5px
          .markImage-abbreviation-banner
            border-right: 1px solid #757575
            display: inline-block
            text-align: center
            min-width:60px
            padding: 1px !important
            border-radius: 5px 0px 0px 5px
            i
              font-size: 20px
            div
              font-size: 12px !important
          .markImage-abbreviation-banner.last-banner
            border-right: 0
            border-radius: 0px 5px 5px 0px
          .markImage-abbreviation-plus
            display: inline-block
            text-align: center
            min-width:40px
          .markImage-abbreviation-banner-detail
            border-radius: 5px
            .q-banner__avatar i
              font-size: 20px !important
            .q-banner__content
              font-size: 12px !important
    .markImage-abbreviation > div
      -webkit-animation: fadeInFromNone 0.1s ease-out
      -moz-animation: fadeInFromNone 0.1s ease-out
      -o-animation: fadeInFromNone 0.1s ease-out
      animation: fadeInFromNone 0.1s ease-out
</style>
<style scoped>
.main-work-permit-card-light-blue {
  border-top: 2px solid #03a9f4;
}
.main-work-permit-card-amber {
  border-top: 2px solid #ffc107;
}
.main-work-permit-card-light-green {
  border-top: 2px solid #8bc34a;
}
</style>